var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    ref: "refreshCard",
    attrs: {
      "action-collapse": "",
      "title": "Daftar Karyawan"
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah ")], 1) : _vm._e()], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.exportKaryawan($event);
      }
    }
  }, [_vm._v("Export")])], 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "size": "xl",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Personal")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIK",
      "label-for": "v-nik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nik",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nik",
            "placeholder": "NIK",
            "type": "number"
          },
          model: {
            value: _vm.form.nik,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nik", $$v);
            },
            expression: "form.nik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "v-nama-lengkap"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama-lengkap",
            "placeholder": "Nama Lengkap"
          },
          model: {
            value: _vm.form.nama_lengkap,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama_lengkap", $$v);
            },
            expression: "form.nama_lengkap"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Foto",
      "label-for": "v-file_foto"
    }
  }, [_c('input', {
    ref: "file_foto",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onSelectedImage
    }
  }), _vm.preview_image ? _c('div', {
    staticClass: "my-2"
  }, [_c('img', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": _vm.preview_image,
      "alt": ""
    }
  })]) : _vm._e()])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kelamin",
      "label-for": "v-jk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jk",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optjk
          },
          model: {
            value: _vm.form.jk,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jk", $$v);
            },
            expression: "form.jk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Agama",
      "label-for": "v-agama"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "agama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optagama
          },
          model: {
            value: _vm.form.agama,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "agama", $$v);
            },
            expression: "form.agama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tempat Lahir",
      "label-for": "v-tempat_lahir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tempat_lahir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tempat_lahir",
            "placeholder": "Tempat Lahir"
          },
          model: {
            value: _vm.form.tempat_lahir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tempat_lahir", $$v);
            },
            expression: "form.tempat_lahir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Lahir",
      "label-for": "v-tanggal_lahir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal_lahir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "date",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.tanggal_lahir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tanggal_lahir", $$v);
            },
            expression: "form.tanggal_lahir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Perkawinan",
      "label-for": "v-status_perkawinan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_perkawinan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_perkawinan
          },
          model: {
            value: _vm.form.status_perkawinan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_perkawinan", $$v);
            },
            expression: "form.status_perkawinan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor HP",
      "label-for": "v-nomor-hp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "no_hp",
      "rules": {
        min: 10,
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nomor-hp",
            "placeholder": "Nomor HP",
            "type": "number"
          },
          model: {
            value: _vm.form.no_hp,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "no_hp", $$v);
            },
            expression: "form.no_hp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "v-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": {
        required: true,
        email: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-email",
            "type": "email",
            "placeholder": "Email"
          },
          model: {
            value: _vm.form.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "email", $$v);
            },
            expression: "form.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nip",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Alamat Lengkap"
          },
          model: {
            value: _vm.form.alamat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "alamat", $$v);
            },
            expression: "form.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookOpenIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Pendidikan")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pendidikan Terakhir",
      "label-for": "v-pendidikan_terakhir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pendidikan_terakhir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-pendidikan_terakhir",
            "placeholder": "Pendidikan Terakhir"
          },
          model: {
            value: _vm.form.pendidikan_terakhir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "pendidikan_terakhir", $$v);
            },
            expression: "form.pendidikan_terakhir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kampus / Sekolah",
      "label-for": "v-kampus_sekolah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kampus_sekolah",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kampus_sekolah",
            "placeholder": "Kampus / Sekolah"
          },
          model: {
            value: _vm.form.kampus_sekolah,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kampus_sekolah", $$v);
            },
            expression: "form.kampus_sekolah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kualifikasi Pendidikan",
      "label-for": "v-kualifikasi_pendidikan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kualifikasi_pendidikan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kualifikasi_pendidikan",
            "placeholder": "Kualifikasi Pendidikan"
          },
          model: {
            value: _vm.form.kualifikasi_pendidikan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kualifikasi_pendidikan", $$v);
            },
            expression: "form.kualifikasi_pendidikan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Kepegawaian")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIP",
      "label-for": "v-nip"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nip",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nip",
            "placeholder": "NIP",
            "type": "number"
          },
          model: {
            value: _vm.form.nip,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nip", $$v);
            },
            expression: "form.nip"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jabatan",
      "label-for": "v-jabatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jabatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optjabatan_id,
            "label": "Jabatan"
          },
          model: {
            value: _vm.form.jabatan_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jabatan_id", $$v);
            },
            expression: "form.jabatan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Karyawan",
      "label-for": "v-status_karyawan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_karyawan
          },
          model: {
            value: _vm.form.status_karyawan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_karyawan", $$v);
            },
            expression: "form.status_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status SP",
      "label-for": "v-status_sp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_sp",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_sp
          },
          model: {
            value: _vm.form.status_sp,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_sp", $$v);
            },
            expression: "form.status_sp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Masa Kerja",
      "label-for": "v-masa_kerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "masa_kerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-masa_kerja",
            "placeholder": "Masa Kerja"
          },
          model: {
            value: _vm.form.masa_kerja,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "masa_kerja", $$v);
            },
            expression: "form.masa_kerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Grade Karyawan",
      "label-for": "v-grade_karyawan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "grade_karyawan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-grade_karyawan",
            "placeholder": "Grade Karyawan"
          },
          model: {
            value: _vm.form.grade_karyawan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "grade_karyawan", $$v);
            },
            expression: "form.grade_karyawan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || "Submit") + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(id)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(foto)",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.url_foto ? _c('img', {
          staticStyle: {
            "width": "50px"
          },
          attrs: {
            "src": item.url_foto,
            "alt": "Foto"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "32"
          }
        })];
      }
    }, {
      key: "cell(jk)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.jk[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.jk[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(jabatan_id)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v(" " + _vm._s(_vm.getJabatanName(data.item)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "row-details",
      fn: function fn(row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }